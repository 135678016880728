import axios from 'axios'
import store from './store/global-store'
import { get } from './store/constants'

const instance = axios.create({
  baseURL: '/dvin/rest/',
  timeout: 180000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// configuring each request before executing it
instance.interceptors.request.use(config => {
  config.headers.Authorization = 'Bearer ' + store.getters[get.TOKEN]
  return config
})

export default instance
