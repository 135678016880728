<template>
  <v-app>

    <AppBar :user="user"></AppBar>

    <!-- Content: depends on the current route. NB: this is idiomatic vue-router code! -->
    <v-main>
      <v-alert border="right" colored-border type="error" elevation="2" v-if="!user">
        You're not logged in. This may cause technical error during the navigation. Please do authenticate yourself.
      </v-alert>

      <router-view/>

    </v-main>

  </v-app>
</template>

<script>
import AppBar from './AppBar'
import { mapGetters } from 'vuex'
import { get } from '../store/constants'

export default {
  name: 'App',

  components: {
    AppBar
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters({
      user: get.CURRENT_USER,
      expiration: get.EXPIRATION
    })
  }
}
</script>
