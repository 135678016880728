// listing all the possible getters in the app - let's keep this sorted please
export const get = Object.freeze({
  CURRENT_USER: 'CURRENT_USER',
  EXPIRATION: 'EXPIRATION',
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  TOKEN: 'TOKEN',
  VERSION: 'VERSION'
})

// listing all the possible mutations in the app - let's keep this sorted please
export const set = Object.freeze({
  APP_INFO: 'APP_INFO',
  AUTH_INFO: 'AUTH_INFO',
  CURRENT_USER: 'CURRENT_USER',
  LOGOUT: 'LOGOUT'
})

// listing all the possible actions in the app - let's keep this sorted please
export const act = Object.freeze({
  FETCH_APP_INFO: 'FETCH_APP_INFO',
  FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
})
