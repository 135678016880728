import { act, get, set } from './constants'
import Base64 from 'Base64'
import axios from 'axios'
import store from './global-store'
import api from '../api'

// the constants for the items in the local storage
const item = Object.freeze({
  TOKEN: 'TOKEN',
  EXPIRATION: 'EXPIRATION'
})

// Our auth state
const state = {
  token: null,
  expiration: null,
  currentUser: null
}

// Accessing the stored items
const getters = {
  // ---
  [get.CURRENT_USER]: state => {
    return state.currentUser
  },
  // ---
  [get.EXPIRATION]: state => {
    return state.expiration
  },
  // ---
  [get.IS_LOGGED_IN]: state => {
    return when => {
      // are we logged in ? trying the get the answer from the state
      if (state.token != null && state.expiration != null) {
        return state.expiration > when
      }

      // since the answer is NO, maybe this is because we refreshed ? so let's find out from the local storage
      const token = localStorage.getItem(item.TOKEN)
      const expiration = new Date(localStorage.getItem(item.EXPIRATION))

      // seems like there's a token, which is not expired yet - let's update our state with that for the next time
      if (token != null && expiration != null) {
        if (when > expiration) {
          console.error('Auto-login failed due to expired token!')
        } else {
          // keeping the auth info in our store
          store.commit(set.AUTH_INFO, {
            token: token,
            expiration: expiration
          })

          // computing an expiration time
          const expirationTime = expiration.getTime() - when.getTime()

          // setting the auto-logout
          setTimeout(() => {
            if (store.getters[get.IS_LOGGED_IN](new Date())) {
              store.dispatch(act.LOGOUT)
            }
          }, expirationTime - 1000)

          // fetching the current user info
          store.dispatch(act.FETCH_CURRENT_USER, true)

          // we're logged in!
          return true
        }
      }

      return false
    }
  },
  // ---
  [get.TOKEN]: state => {
    return state.token
  }
}

// Updating the stored items
const mutations = {
  // ---
  [set.AUTH_INFO] (state, authInfo) {
    state.token = authInfo.token
    localStorage.setItem(item.TOKEN, authInfo.token)
    state.expiration = authInfo.expiration
    localStorage.setItem(item.EXPIRATION, authInfo.expiration)
  },
  // ---
  [set.CURRENT_USER] (state, user) {
    state.currentUser = user
  },
  // ---
  [set.LOGOUT] (state) {
    state.token = null
    localStorage.removeItem(item.TOKEN)

    state.expiration = null
    localStorage.removeItem(item.EXPIRATION)

    state.currentUser = null
  }
}

// Performing actions involving stored items
const actions = {
  // ---
  [act.FETCH_CURRENT_USER] ({ commit, state }, force) {
    if (!state.currentUser || force) {
      return api.get('user/current')
        .then(res => {
          if (res.data.status === 200) {
            commit(set.CURRENT_USER, res.data.entity)
          }
          // TODO handle issue - snackbar
        })
        .catch(error => {
          // TODO handle issue - snackbar
          console.error(error)
        })
    }
  },
  // ---
  [act.LOGIN] ({ dispatch, commit }, payload) {
    return axios.post('dvin/oauth/token', {}, {
      params: { grant_type: 'client_credentials' },
      headers: { Authorization: 'Basic ' + Base64.btoa(payload.email + ':' + payload.password) }
    })
      .then(res => {
        // the request went through, and the user got a token !
        if (res.data != null && res.data.access_token != null) {
          // making the token expiring 10 seconds before it really expires on the server
          const expirationTime = (Math.max(res.data.expires_in, 11) - 10) * 1000

          // keeping the auth info in our store
          commit(set.AUTH_INFO, {
            token: res.data.access_token,
            expiration: new Date(new Date().getTime() + expirationTime)
          })

          // auto-logout
          setTimeout(() => {
            if (store.getters[get.IS_LOGGED_IN](new Date())) {
              dispatch(act.LOGOUT)
            }
          }, expirationTime - 1000)

          // fetching the current user info
          dispatch(act.FETCH_CURRENT_USER, true)

          // the expiration time is used by the Login component
          // to auto-route back to the login page once the token is expired
          return Promise.resolve()
        } else {
          // no token for this user (bad mail or password)
          return Promise.reject(new Error('No token in response !!'))
        }
      })
      .catch(err => {
        // the request failed technically
        // TODO replace by message to snackbar
        return Promise.reject(err)
      })
  },
  // ---
  [act.LOGOUT] ({ commit }) {
    commit(set.LOGOUT)
  }
}

// Exporting this store
export default { state, getters, mutations, actions }
