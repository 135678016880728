<template>
  <div>
    <v-app-bar color="primary" dense>

      <v-toolbar-title class="text--secondary">D-VIN</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text :to="pathCORE" v-if="user" >
        Search
      </v-btn>

      <v-btn icon :to="pathLOGIN" v-if="!user">
        <v-icon>mdi-login</v-icon>
      </v-btn>

      <v-btn icon :to="pathACCOUNT" v-if="user">
        <v-icon>mdi-account</v-icon>
      </v-btn>

    </v-app-bar>
  </div>
</template>

<script>
import path from '../path'

export default {
  name: 'AppBar',
  props: ['user'],
  data () {
    return {
      pathLOGIN: path.LOGIN,
      pathACCOUNT: path.ACCOUNT,
      pathCORE: path.CORE
    }
  }
}
</script>

<style scoped>

</style>
