// auth levels
export const AuthLevels = Object.freeze({
  OPEN: 1,
  USER: 2,
  SUPER: 3,
  ADMIN: 4
})

// utility function to help declare routes in 1 line
export const route = function (path, component, authLevel) {
  return {
    path: path,
    name: path,
    component: component,
    meta: {
      authLevel: authLevel
    }
  }
}

// utility function to set and return the given field value in the local storage
// if no value is provided, then the local storage value is returned
export const sync = function (fieldName, fieldValue) {
  if (!fieldValue) {
    return localStorage.getItem(fieldName)
  }
  localStorage.setItem(fieldName, fieldValue)
  return fieldValue
}
