import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module-auth'
import { act, get, set } from './constants'
import api from '../api'

Vue.use(Vuex)

// the constants for the items in the local storage
// const item = Object.freeze({})

// Our auth state
const state = {
  version: null
}

// Accessing the stored items
const getters = {
  // ---
  [get.VERSION]: state => {
    return state.version
  }
}

// Updating the stored items
const mutations = {
  // ---
  [set.APP_INFO] (state, appInfos) {
    state.version = appInfos.version
  }
}

// Performing actions involving stored items
const actions = {
  // ---
  [act.FETCH_APP_INFO] ({ commit }) {
    return api.get('appinfos/read')
      .then(res => {
        if (res.data.status === 200) {
          commit(set.APP_INFO, res.data.entity)
        }
        // TODO handle issue - snackbar
      })
      .catch(error => {
        // TODO handle issue - snackbar
        console.log(error)
      })
  }
}

export default new Vuex.Store({

  // checks in dev that we do not Vuex state is not mutated outside of mutation handlers
  strict: process.env.NODE_ENV !== 'production',

  // THIS store's properties
  state,
  getters,
  mutations,
  actions,

  // aggregating the other stores here
  modules: {
    auth
  }
})
