// to connect this router to the main Vue instance
import Vue from 'vue'
import { AuthLevels, route } from './util'
import path from './path'
import Router from 'vue-router'
import store from './store/global-store'
import { get } from './store/constants'

Vue.use(Router)

// components to lazily load - this is how it's done
const Home = () => import('./views/Home')
const Login = () => import('./views/account/Login')
const Account = () => import('./views/account/Account')
const Core = () => import('./views/core/Core')

const routes = [
  { path: '*', redirect: '/home' }, // any bad route goes back to home
  route(path.HOME, Home, AuthLevels.OPEN),
  route(path.LOGIN, Login, AuthLevels.OPEN),
  route(path.ACCOUNT, Account, AuthLevels.USER),
  route(path.CORE, Core, AuthLevels.USER)
]

const router = new Router({
  mode: 'history',
  routes
})

// preventing the access to guarded routes
router.beforeEach((to, from, next) => {
  if (to.meta.authLevel && to.meta.authLevel !== AuthLevels.OPEN) {
    // NB 1: check the auth level, display a message when the user has not the right level
    // NB 2: filtering out menu entries the user should never see
    if (!store.getters[get.IS_LOGGED_IN](new Date())) {
      next({ path: path.LOGIN, query: { next: to.fullPath } })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

// the router instance
export default router
